<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Dashboard component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      cpuused: 50,
      cpu_progresscolor: "#20a53a",
      memory: 0,
      memory_progresscolor: "#20a53a",
      hostname: "",
      statistics: [],
      options: {
        chart: {
          id: "dashchart",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [],
      userinfo:[]
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("dashboard.text");
    that.items = [
      {
        text: that.$t("dashboard.text"),
        href: "/",
      },
    ];
    that.userinfo = JSON.parse(localStorage.getItem("user"));

  },
  methods: {
    handdleMsg(msg) {},
    getcpuused() {
      if (this.cpuused >= 80) {
        this.cpu_progresscolor = "#f7b851";
      } else if (this.cpuused >= 90) {
        this.cpu_progresscolor = "#ff0017";
      } else {
        this.cpu_progresscolor = "#20a53a";
      }
    },
    getmemory() {
      if (this.memory >= 80) {
        this.memory_progresscolor = "#f7b851";
      } else if (this.memory >= 90) {
        this.memory_progresscolor = "#ff0017";
      } else {
        this.memory_progresscolor = "#20a53a";
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
    </div>
  </Layout>
</template>